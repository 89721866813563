/* * {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    transition: all 300ms ease-in-out;
} */

button {
    color: white;
    border: none;
    background: none;
}

.call-btn-container {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    gap : 10px;
}

.call-btn-item {
    padding: 8px;
    border-radius: 4px;
    border: 2px solid #1B7A87;
}
/* 
button {
    border: none;
    outline: none;
    color: white;
    background: orangered;
    padding: 10px 20px;
    border-radius: 10px;
}

button:hover {
    background: red;
} */

/* .controls {
    padding: 15px 20px;
    border-radius: 10px;
    width: 70%;
    height: 50px;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px black;
    position: absolute;
    left: 50%;
    transform: translateX(-50% , 0); 
    bottom: 10px;
} */

.controls {
    padding: 15px 20px;
    border-radius: 10px;
    width: 70vw;
    height: 2.5rem;
    /* background: #212121; */
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 20px black;
    position: absolute;
    margin: auto;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
}
.btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid #1B7A87;
    border-radius : 8px ; */
}

.material-icons {
    font-size: 32px;
  
    /* background-color: red; */
    color: #1B7A87; 


}
.material-icons-outlined{
    font-size: 32px;
    color: #1B7A87; 
}
.link-text {
    color: #1B7A87; 
}

.call-endbtn{
    background-color: #ce3b2f !important;
    border : none ;
   
}
.call-endbtn .material-icons {
    color :white ;
}

/* For badge  */
.supervisor-button {
    position: relative;
  }
  
  .notification {
    position: absolute;
    top: -5px; 
    right: -5px; 
    width: 20px;
    height: 20px; 
    background-color: #2c3e50; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px; 
  }
  