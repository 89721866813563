.create-package-container {
  max-width: 600px;
  margin-top: 100px;
  margin: auto; /* Center horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}


.create-package-container h1 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  color: #333;
}

.create-package-container form {
  display: flex;
  flex-direction: column;
}

.create-package-container label {
  margin-bottom: 10px;
}

.create-package-container input[type="text"],
.create-package-container input[type="number"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.feature-item {
  flex: 0 1 calc(33.33% - 10px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.feature-item label {
  margin-left: 2 px;
}

.save-button {
  padding: 15px 30px;
  margin-top: 20px;
  align-self: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #0056b3;
}
.features-table {
  margin-right: 0px;
  border-collapse: collapse;
}

.features-table td {
  
  padding: 0;
}

.include-checkbox {
  /* Other styles */
  /* Adjust margin to add space between checkbox and feature name */
  margin-right: 0px;
}
