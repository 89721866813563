

.frame {
    width: 30%;
    /* min-width: 50%; */
    /* max-width: 70%; */
    height: 80%;
    /* min-height: 300px; */
    /* max-height: 720px; */
    background: #E6EAEA;
    margin-top: -1;
    justify-content: top;
    /* display: flex; */
    align-items: top;
    margin-bottom: 6%;
    border-radius: 1%;
    /* margin-top: 18*/
  }
  @media screen and (max-width: 360px) {
    .frame {
      width: 100%;
      height: 100vh;
    }
  }
  .frame #sidepanel {
    float: left;
    min-width: 280px;
    max-width: 340px;
    width: 40%;
    height: 100%;
    background: #2c3e50;
    color: #f5f5f5;
    overflow: hidden;
    position: relative;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel {
      width: 58px;
      min-width: 58px;
    }
  }
  .frame #sidepanel #profile {
    width: 80%;
    margin: 25px auto;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile {
      width: 100%;
      margin: 0 auto;
      padding: 5px 0 0 0;
      background: #32465a;
    }
  }
  .frame #sidepanel #profile.expanded   {
    height: 210px;
    line-height: initial;
  }
  .frame #sidepanel #profile.expanded   p {
    margin-top: 20px;
  }
  .frame #sidepanel #profile.expanded   i.expand-button {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
  .frame #sidepanel #profile   {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    -moz-transition: 0.3s height ease;
    -o-transition: 0.3s height ease;
    -webkit-transition: 0.3s height ease;
    transition: 0.3s height ease;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   {
      height: 55px;
    }
  }
  .frame #sidepanel #profile   img {
    width: 50px;
    border-radius: 50%;
    padding: 3px;
    border: 2px solid #e74c3c;
    height: auto;
    float: left;
    cursor: pointer;
    -moz-transition: 0.3s border ease;
    -o-transition: 0.3s border ease;
    -webkit-transition: 0.3s border ease;
    transition: 0.3s border ease;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   img {
      width: 40px;
      margin-left: 4px;
    }
  }
  .frame #sidepanel #profile   img.online {
    border: 2px solid #2ecc71;
  }
  .frame #sidepanel #profile   img.away {
    border: 2px solid #f1c40f;
  }
  .frame #sidepanel #profile   img.busy {
    border: 2px solid #e74c3c;
  }
  .frame #sidepanel #profile   img.offline {
    border: 2px solid #95a5a6;
  }
  .frame #sidepanel #profile   p {
    float: left;
    margin-left: 15px;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   p {
      display: none;
    }
  }
  .frame #sidepanel #profile   i.expand-button {
    float: right;
    margin-top: 23px;
    font-size: 0.8em;
    cursor: pointer;
    color: #435f7a;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   i.expand-button {
      display: none;
    }
  }
  .frame #sidepanel #profile   #status-options {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 150px;
    margin: 70px 0 0 0;
    border-radius: 6px;
    z-index: 99;
    line-height: initial;
    background: #435f7a;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   #status-options {
      width: 58px;
      margin-top: 57px;
    }
  }
  .frame #sidepanel #profile   #status-options.active {
    opacity: 1;
    visibility: visible;
    margin: 75px 0 0 0;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   #status-options.active {
      margin-top: 62px;
    }
  }
  .frame #sidepanel #profile   #status-options:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #435f7a;
    margin: -8px 0 0 24px;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   #status-options:before {
      margin-left: 23px;
    }
  }
  .frame #sidepanel #profile   #status-options ul {
    overflow: hidden;
    border-radius: 6px;
  }
  .frame #sidepanel #profile   #status-options ul li {
    padding: 15px 0 30px 18px;
    display: block;
    cursor: pointer;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   #status-options ul li {
      padding: 15px 0 35px 22px;
    }
  }
  .frame #sidepanel #profile   #status-options ul li:hover {
    background: #496886;
  }
  .frame #sidepanel #profile   #status-options ul li span.status-circle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 0 0 0;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   #status-options ul li span.status-circle {
      width: 14px;
      height: 14px;
    }
  }
  .frame #sidepanel #profile   #status-options ul li span.status-circle:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    margin: -3px 0 0 -3px;
    background: transparent;
    border-radius: 50%;
    z-index: 0;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   #status-options ul li span.status-circle:before {
      height: 18px;
      width: 18px;
    }
  }
  .frame #sidepanel #profile   #status-options ul li p {
    padding-left: 12px;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #profile   #status-options ul li p {
      display: none;
    }
  }
  .frame #sidepanel #profile   #status-options ul li#status-online span.status-circle {
    background: #2ecc71;
  }
  .frame #sidepanel #profile   #status-options ul li#status-online.active span.status-circle:before {
    border: 1px solid #2ecc71;
  }
  .frame #sidepanel #profile   #status-options ul li#status-away span.status-circle {
    background: #f1c40f;
  }
  .frame #sidepanel #profile   #status-options ul li#status-away.active span.status-circle:before {
    border: 1px solid #f1c40f;
  }
  .frame #sidepanel #profile   #status-options ul li#status-busy span.status-circle {
    background: #e74c3c;
  }
  .frame #sidepanel #profile   #status-options ul li#status-busy.active span.status-circle:before {
    border: 1px solid #e74c3c;
  }
  .frame #sidepanel #profile   #status-options ul li#status-offline span.status-circle {
    background: #95a5a6;
  }
  .frame #sidepanel #profile   #status-options ul li#status-offline.active span.status-circle:before {
    border: 1px solid #95a5a6;
  }
  .frame #sidepanel #profile   #expanded {
    padding: 100px 0 0 0;
    display: block;
    line-height: initial !important;
  }
  .frame #sidepanel #profile   #expanded label {
    float: left;
    clear: both;
    margin: 0 8px 5px 0;
    padding: 5px 0;
  }
  .frame #sidepanel #profile   #expanded input {
    border: none;
    margin-bottom: 6px;
    background: #32465a;
    border-radius: 3px;
    color: #f5f5f5;
    padding: 7px;
    width: calc(100% - 43px);
  }
  .frame #sidepanel #profile   #expanded input:focus {
    outline: none;
    background: #435f7a;
  }
  .frame #sidepanel #search {
    border-top: 1px solid #32465a;
    border-bottom: 1px solid #32465a;
    font-weight: 300;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #search {
      display: none;
    }
  }
  .frame #sidepanel #search label {
    position: absolute;
    margin: 10px 0 0 20px;
  }
  .frame #sidepanel #search input {
    font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
    padding: 10px 0 10px 46px;
    width: calc(100% - 25px);
    border: none;
    background: #32465a;
    color: #f5f5f5;
  }
  .frame #sidepanel #search input:focus {
    outline: none;
    background: #435f7a;
  }
  .frame #sidepanel #search input::-webkit-input-placeholder {
    color: #f5f5f5;
  }
  .frame #sidepanel #search input::-moz-placeholder {
    color: #f5f5f5;
  }
  .frame #sidepanel #search input:-ms-input-placeholder {
    color: #f5f5f5;
  }
  .frame #sidepanel #search input:-moz-placeholder {
    color: #f5f5f5;
  }
  .frame #sidepanel #contacts {
    height: calc(100% - 177px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #contacts {
      height: calc(100% - 149px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .frame #sidepanel #contacts::-webkit-scrollbar {
      display: none;
    }
  }
  .frame #sidepanel #contacts.expanded {
    height: calc(100% - 334px);
  }
  .frame #sidepanel #contacts::-webkit-scrollbar {
    width: 8px;
    background: #2c3e50;
  }
  .frame #sidepanel #contacts::-webkit-scrollbar-thumb {
    background-color: #243140;
  }
  .frame #sidepanel #contacts ul li.contact {
    position: relative;
    padding: 10px 0 15px 0;
    font-size: 0.9em;
    cursor: pointer;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #contacts ul li.contact {
      padding: 6px 0 46px 8px;
    }
  }
  .frame #sidepanel #contacts ul li.contact:hover {
    background: #32465a;
  }
  .frame #sidepanel #contacts ul li.contact.active {
    background: #32465a;
    border-right: 5px solid #435f7a;
  }
  .frame #sidepanel #contacts ul li.contact.active span.contact-status {
    border: 2px solid #32465a !important;
  }
  .frame #sidepanel #contacts ul li.contact   {
    width: 88%;
    margin: 0 auto;
    position: relative;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #contacts ul li.contact   {
      width: 100%;
    }
  }
  .frame #sidepanel #contacts ul li.contact   span {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #2c3e50;
    background: #95a5a6;
  }
  .frame #sidepanel #contacts ul li.contact   span.online {
    background: #2ecc71;
  }
  .frame #sidepanel #contacts ul li.contact   span.away {
    background: #f1c40f;
  }
  .frame #sidepanel #contacts ul li.contact   span.busy {
    background: #e74c3c;
  }
  .frame #sidepanel #contacts ul li.contact   img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #contacts ul li.contact   img {
      margin-right: 0px;
    }
  }
  .frame #sidepanel #contacts ul li.contact   .meta {
    padding: 5px 0 0 0;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #contacts ul li.contact   .meta {
      display: none;
    }
  }
  .frame #sidepanel #contacts ul li.contact   .meta .name {
    font-weight: 600;
  }
  .frame #sidepanel #contacts ul li.contact   .meta .preview {
    margin: 5px 0 0 0;
    padding: 0 0 1px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-transition: 1s all ease;
    -o-transition: 1s all ease;
    -webkit-transition: 1s all ease;
    transition: 1s all ease;
  }
  .frame #sidepanel #contacts ul li.contact   .meta .preview span {
    position: initial;
    border-radius: initial;
    background: none;
    border: none;
    padding: 0 2px 0 0;
    margin: 0 0 0 1px;
    opacity: .5;
  }
  .frame #sidepanel #bottom-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .frame #sidepanel #bottom-bar button {
    float: left;
    border: none;
    width: 50%;
    padding: 10px 0;
    background: #32465a;
    color: #f5f5f5;
    cursor: pointer;
    font-size: 0.85em;
    font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #bottom-bar button {
      float: none;
      width: 100%;
      padding: 15px 0;
    }
  }
  .frame #sidepanel #bottom-bar button:focus {
    outline: none;
  }
  .frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: 1px solid #2c3e50;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #bottom-bar button:nth-child(1) {
      border-right: none;
      border-bottom: 1px solid #2c3e50;
    }
  }
  .frame #sidepanel #bottom-bar button:hover {
    background: #435f7a;
  }
  .frame #sidepanel #bottom-bar button i {
    margin-right: 3px;
    font-size: 1em;
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #bottom-bar button i {
      font-size: 1.3em;
    }
  }
  @media screen and (max-width: 735px) {
    .frame #sidepanel #bottom-bar button span {
      display: none;
    }
  }
  .frame .content {
    float: left;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    position: relative;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 735px) {
    .frame .content {
      width: calc(100% - 58px);
      min-width: 300px !important;
    }
  }
  /* @media screen and (min-width: 900px) {
    .frame .content {
      width: calc(100% - 340px);
    }
  } */

  .frame .content .chat-header{
    width: 94%;
    padding: 2% 0;
    margin: 10px 3%;
    height: 10%;
    line-height: 60px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: -1%; */
    /* border-top-left-radius: inherit; */
    border-radius: 2%;
  }
  .frame .content .chat-header .chat-heading{
    padding : 0 5% ;
  }

  #chat-close-btn {
    padding-right: 5%;
    margin: auto;
    cursor:default;
  }
  #participants-close-btn {
    padding-right: 5%;
    cursor:default;
  }
  .frame .content .contact-profile p {
    float: left;
  }
  .frame .content .contact-profile .social-media {
    float: right;
  }
  .frame .content .contact-profile .social-media i {
    margin-left: 14px;
    cursor: pointer;
  }
  .frame .content .contact-profile .social-media i:nth-last-child(1) {
    margin-right: 20px;
  }
  .frame .content .contact-profile .social-media i:hover {
    color: #435f7a;
  }
  .frame .content .messages {
    display: flex;
    flex-direction: column;
    height: auto;
    /* width: auto; */
    /* margin: auto; */
    /* align-items: center; */
    overflow-x:hidden ;
    justify-content: flex-start;
    overflow-y: scroll;
    /* border: 2px solid red; */
    /* height: 300px; */
  }
  @media screen and (max-width: 735px) {
    .frame .content .messages {
      max-height: calc(100% - 105px);
    }
  }
  .frame .content .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }
  .frame .content .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .frame .content .messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
  }
  .frame .content .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
  }
  .frame .content .messages ul li.sent img {
    margin: 6px 8px 0 0;
  }
  .frame .content .messages ul li.sent p {
    background: #435f7a;
    color: #f5f5f5;
  }
  .frame .content .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
  }
  .frame .content .messages ul li.replies p {
    background: #f5f5f5;
    float: right;
  }
  .frame .content .messages ul li img {
    width: 22px;
    border-radius: 50%;
    float: left;
  }
  .frame .content .messages ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;
  }
  @media screen and (min-width: 735px) {
    .frame .content .messages ul li p {
      max-width: 300px;
    }
  }
  .frame .content .message-input {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 2px solid#1b7a87;
  position: absolute;
  bottom: 0;
  width: 92%;
  z-index: 99;
  margin : 1% ;
  border-radius: 8px;
  justify-content: space-between;
  background: white;
  }
  .frame .content .message-input input[type="text"] {
    /* flex-grow: 2; */
    padding: 10px;
    border-radius: 5px;
    /* border: 1px solid #ccc; */
    width: 90%;
    background-color: transparent;
}
.frame .content .message-input button {
  background-color: #1b7a87;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  margin-left: 10px;
  cursor: default;
} 

.frame .content .message-input  button:hover {
  background: #0e565f;
}
.frame .content .message-input button:focus {
  outline: none;
}

  .frame .content .message-input  input {
    font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
    float: left;
    border: none;
    width: calc(100% - 90px);
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    color: #32465a;
  }
  @media screen and (max-width: 735px) {
    .frame .content .message-input   input {
      padding: 15px 32px 16px 8px;
    }
  }
  .frame .content .message-input   input:focus {
    outline: none;
  }
  .frame .content .message-input   .attachment {
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.1em;
    color: #435f7a;
    opacity: .5;
    cursor: pointer;
  }
  @media screen and (max-width: 735px) {
    .frame .content .message-input   .attachment {
      margin-top: 17px;
      right: 65px;
    }
  }

  @media screen and (max-width: 735px) {
    .frame .content .message-input   button {
      padding: 16px 0;
    }
  }

  /* Chat tab styles */
  /* .tab-nav {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  
  .tab-nav button {
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
  }
  
  .tab-nav button.active {
    background-color: #ddd;
    font-weight: bold;
  }
   */
   /* .tab-nav {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
  }
  
  .tab-nav button {
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .tab-nav button.active {
    background-color: #ddd;
    font-weight: bold;
  }
  
  .tab-content {
    overflow-y: auto;
    max-height: calc(100% - 100px); 
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .chat {
    list-style: none;
    padding: 0;
  }
  
  .message-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  
  .input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .send-msg-btn {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
   */

   .tab-nav {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    
  }
  
  .tab-nav button {
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    color: #1b7a87;
  }
  
  .tab-nav button.active {
    background-color: #099aae4a;
    font-weight: bold;
  }
  
  .tab-nav .close-btn {
    background-color: #ff4d4d;
    color: white;
    flex: 0;
    margin-left: auto;
    border-radius: 5px;
  }
 
  .tab-content {
    overflow-y: auto;
    max-height: calc(100% - 100px); /* Adjust according to your layout */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .chat {
    list-style: none;
    padding: 0;
  }
  
  .message-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  
  .input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .send-msg-btn {
    /* background-color: #007bff; */
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .group-messages-container{
    margin-top:1rem;
  }

  /* For pariticpants list */
  .participants-container{
    background: white;
    height: 100%;
    margin: .5rem;
  }
  