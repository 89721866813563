.landing-page {
    font-family: Arial, sans-serif;
    max-width: 400px;
    margin: 40px auto;
    text-align: center;
}

.logo {
    width: 200px;
    margin: 15px auto;
}

.meeting-code-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
}

.join-button {
    background-color: #4285F4;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-button {
    background-color: #fff;
    color: #4285F4;
    padding: 10px 20px;
    border: 1px solid #4285F4;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20%;
}

.footer-text {
    font-size: 12px;
    color: #666;
}

.footer-link {
    text-decoration: none;
    color: #4285F4;
}

.footer-link:hover {
    text-decoration: underline;
}