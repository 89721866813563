.coupon-list {
  padding: 20px;
}

.coupon-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.coupon {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.coupon-details {
  margin-bottom: 20px;
}

.features-list {
  list-style-type: none;
  padding: 0;
}

.features-list li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.features-list .feature-check {
  color: green;
  margin-right: 10px;
}

.coupon-price {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  font-weight: bold;
}

.buy-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  bottom: 20px;
  left: 100px;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.buy-button:hover, .close-button:hover {
  opacity: 0.8;
}
