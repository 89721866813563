.msg-flex-col{
  display: flex;
  flex-direction: column;
}
.msg-container {
  /* border: 2px solid #dedede; */
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 0.8rem 0.6rem;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-bottom: 2%;
}
.left-msg {
  justify-content: flex-start; /* Align items to the start of the flex container (left-aligned) */
}

.right-msg {
  justify-content: flex-end; /* Align items to the end of the flex container (right-aligned) */
}
.local-msg {
  /* border-color: #ccc; */
  background-color: #50d0e2;
}
.remote-msg {
  /* border-color: #ccc; */
  background-color: #1b7a8770;
}
.msg-info-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  padding-left: 2%;
}

.msg-info-name {
  font-size: 1rem;
  font-weight: 700;
  margin-right: 10px;
  font-family: sans-serif;
}

.msg-container-darker {
  border-color: #ccc;
  background-color: #ddd;
}

.msg-container::after {
  content: "";
  clear: both;
  display: table;
}

.msg-container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.msg-container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
  font-size: 1rem;
}
.msg-info-time {
  font-size: 0.7em;
}

.msg-container .msg-text {
  font-size: 0.8em;
  word-wrap: break-word;
}
