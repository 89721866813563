/* emoticonBar.css */
/* 
.emoticon-bar {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
  }
  
  .emoticon {
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .emoticon:hover {
    background-color: #f0f0f0;
  } */

/* emoticonBar.css */

.emoticon-bar {
  display: flex;
  position: absolute;
  top: -55px;
  /* left: 69%; */
  right: 0%;
  /* transform: translateX(-50%); */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 24px;
  width: 300px;
  height: 2.5rem;
  padding: 5px;
  justify-content: space-evenly;
}

.emoticon {
  font-size: 20px;
  cursor: pointer;
  margin: 0 5px; /* Add margin between emoticons */

  transition: transform 0.5s;
}
.emoticon:hover {
  background-color: #f0f0f0;
}

.emoticon-flying {
  animation: fly 4s forwards;
}



@keyframes fly {
  0% {
    transform: translateY(100vh); 
    opacity: 0; 
  }
  50% {
    transform: translateY(10); 
    opacity: 1; 
  }
  100% {
    transform: translateY(-200px); 
    opacity: 0; 
  }
}
.emojis {
  position: fixed;
  top: 10px; /* Adjust as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  z-index: 9999; /* Ensure emojis are displayed above other components */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
