.mainContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92vh;
    border: 2px solid black;
    border-radius: 8px;
    width: 40%;
    margin: auto;
    margin-top: 2%;
    /* height: 98vh; */
    background: #f1eded;

  }
  
  .titleContainer {
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
  }
  
  .resultContainer,
  .historyItem {
    flex-direction: row;
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-between;
  }
  
  .historyContainer {
    flex-direction: column;
    display: flex;
    height: 200px;
    align-items: center;
    flex-grow: 5;
    justify-content: flex-start;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 260px;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
  }
  
  .inputContainer > .errorLabel {
    color: red;
    font-size: 12px;
  }
  
  .inputBox {
    height: 48px;
    width: 100%;
    font-size: large;
    border-radius: 8px;
    border: 1px solid grey;
    padding-left: 8px;
  }


  
  input[type='button'] {
    border: none;
    background: cornflowerblue;
    color: white;
    padding: 12px 24px;
    margin: 8px;
    font-size: 24px;
    border-radius: 8px;
    cursor: pointer;
    margin: auto;
  }