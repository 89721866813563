
/*  side bar view */
/* .sidebar-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }  
   .rvideo-container {
    cursor: pointer;
    height: 80%;
  }   
   
  .sidebar { 
    width: 30%;
    margin :auto ;
  } */


  .sidebar-container {
    display: flex;
    flex-direction: column; /* Display content in a column */
    width: 100%; /* Set the width of the sidebar */
    overflow: hidden; /* Prevent scrolling */
}

.rvideo-container {
    cursor: pointer;
    height: auto; /* Allow content to adjust its own height */
    flex-grow: 1; /* Allow content to expand to fill available space */
    width: 100%;
}

.sidebar { 
    margin: auto; /* Center the sidebar horizontally */
}


   /* Adjust the CSS classes for different grid layouts */


   /* Sidebar container *//* Sidebar container */

