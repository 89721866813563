
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-package-button,
.view-package-button {
  padding: 20px 40px;
  font-size: 24px;
  margin-bottom: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-package-button:hover,
.view-package-button:hover {
  background-color: #0056b3;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  width: 200px; /* Adjust the width of the logo as needed */
  height: auto; /* Maintain aspect ratio */
}